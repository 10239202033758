<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row class="mb-2">
          <b-col
            cols="12"
            md="6"
          >
            Buat Peminjaman
          </b-col>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-link to="/loan">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.modal-form
                  variant="secondary"
                >
                  <feather-icon
                    icon="ChevronLeftIcon"
                    class="mr-50"
                  />
                  <span>Kembali</span>
                </b-button>
              </b-link>
            </div>
          </b-col>
        </b-row>
        <b-alert
          v-if="errors.length > 0"
          variant="danger"
          show
        >
          <div class="alert-body">
            <p
              v-for="error in errors"
              :key="error"
            >
              {{ error }}
            </p>
          </div>
        </b-alert>
        <form @submit.prevent="handleSave">
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-card class="border">
                <b-row v-if="$can('update', 'Loan')">
                  <b-col
                    md="12"
                    cols="12"
                  >
                    <b-form-group
                      label="Peminjam"
                    >
                      <v-select
                        v-model="requester"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        value="id"
                        label="title"
                        :options="userList"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <b-form-group
                      label="Jenis Inventaris"
                    >
                      <b-form-select
                        v-model="form.inventory_type"
                        :disabled="form.inventory_type != null"
                        :options="inventoryTypes"
                        @change="getInventoryList()"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <b-form-group
                      label="Reset Form"
                    >
                      <b-button
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        variant="outline-warning" :disabled = resetStatus
                        @click="handleReset()"
                      >
                        <feather-icon
                          icon="RefreshCcwIcon"
                          class="mr-25"
                        />
                        <span>Reset</span>
                      </b-button>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <b-form-group
                      label="Tanggal Mulai"
                    >
                      <flat-pickr
                        v-model="form.loan_start" :disabled = resetStatus
                        class="form-control"
                        @input="getInventoryList(true)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <b-form-group
                      label="Tanggal Selesai"
                    >
                      <flat-pickr
                        v-model="form.loan_end" :disabled = resetStatus
                        class="form-control"
                        @input="getInventoryList(true)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="12"
                    cols="12"
                  >
                    <b-form-group
                      label="Tujuan Peminjaman"
                    >
                      <b-form-textarea
                        v-model="form.loan_reason"
                        placeholder="Enter Reason"
                        rows="2"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="12"
                    cols="12"
                  >
                    <b-form-group
                      label="Anggota Peminjam"
                    >
                      <v-select
                        v-model="userSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        multiple
                        value="id"
                        label="title"
                        :options="userList"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="6"
                    cols="12"
                  >
                    <b-form-checkbox
                      v-model="form.with_exception"
                      class="mb-1"
                      name="check-button"
                      switch
                      inline
                    >
                      Keperluan Tugas Akhir
                    </b-form-checkbox>
                  </b-col>
                  <b-col
                    v-if="form.with_exception"
                    md="12"
                    cols="12"
                  >
                    <b-form-group
                      label="Deskripsi"
                    >
                      <b-form-textarea
                        v-model="form.description"
                        placeholder="Enter Description"
                        rows="2"
                      />
                    </b-form-group>
                    <b-form-group
                      label="Upload Izin Lokasi Syuting"
                    >
                      <b-form-file
                        v-model="form.file_location_permission"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      />
                      <span>{{ form.location_permission_path }}</span>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-card class="border">
                <b-form-group
                  label="Tambahkan Inventaris"
                >
                  <b-form
                    ref="form"
                    :style="{height: trHeight}"
                    class="repeater-form"
                    @submit.prevent="repeateAgain"
                  >
                    <b-row
                      v-for="(item, index) in items"
                      :id="item.id"
                      :key="item.id"
                      ref="row"
                    >

                      <!-- Item Name -->
                      <b-col md="10">
                        <b-form-group
                          label="Item Name"
                          label-for="item-name"
                        >
                          <v-select
                            v-model="item.inventory_id"
                            value="id"
                            label="title"
                            :options="inventoryList"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                        lg="2"
                        md="2"
                        class="mb-50"
                      >
                        <div class="d-flex align-items-center justify-content-end">
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            class="mt-0 mt-md-2"
                            @click="removeItem(index)"
                          >
                            <feather-icon
                              icon="XIcon"
                            />
                          </b-button>
                        </div>
                      </b-col>
                      <b-col cols="12">
                        <hr>
                      </b-col>
                    </b-row>
                  </b-form>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    :disabled="!form.inventory_type"
                    class="mt-1"
                    @click="repeateAgain"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>Add New</span>
                  </b-button>
                </b-form-group>
              </b-card>
            </b-col>
          </b-row>
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-50"
              />
              <span>Simpan</span>
            </b-button>
          </div>
        </form>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton, BLink, BAlert, BFormGroup, BFormTextarea, BFormCheckbox, BForm, BFormSelect, BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import data from '@/router/routes/ui-elements'

export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    BForm,
    BFormSelect,
    BFormFile,

    flatPickr,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      form: {
        loan_start: this.getCurrentDate(),
        loan_end: this.getCurrentDate(),
        with_exception: false,
        inventory_type: null,
        members: [],
        inventories: [],
        file_location_permission: null,
      },
      resetStatus: false,
      editable: false,
      errors: [],
      items: [],
      nextTodoId: 1,
      inventoryList: [],
      userList: [],
      userSelected: [],
      inventoryTypes: [
        { value: null, text: 'Select Inventory Type' },
        { value: 'facility', text: 'Sarana' },
        { value: 'tool', text: 'Alat' },
      ],
      requester: null,
    }
  },
  mounted() {
    this.getUserList()
    this.initTrHeight()
    if (this.$route.params.id != null) {
      this.getLoanDetail()
    }
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    getInventoryList(reset = false) {
      const data = {
        params: {
          'page[size]': 0,
          'filter[is_active]': 1,
          'start_date': this.form.loan_start,
          'end_date': this.form.loan_end,
        },
      }

      if (this.form.inventory_type || this.$route.params.id) {
        this.$http.get(`/v1/inventory/list/${this.form.inventory_type}`, data)
          .then(response => {
            if (reset) {
              this.inventoryList = response.data.data.map(item => ({
                value: item.id,
                title: `${item.inventory_code} - ${item.name}`,
              }))
            } else {
              response.data.data.forEach(item => {
                const inventory = {
                  value: item.id,
                  title: `${item.inventory_code} - ${item.name}`,
                }
  
                this.inventoryList.push(inventory)
              })
            }
          })
          .catch(error => {
            this.errorToast(error.response.data.messages)
          })
      }
    },
    getUserList() {
      const data = {
        params: {
          'page[size]': 0,
          'filter[role]': 'student',
        },
      }

      this.$http.get('/v1/user/list', data)
        .then(response => {
          this.userList = response.data.data.map(item => ({
            value: item.id,
            title: item.nim ? `${item.name} (${item.nim})` : item.name,
          }))
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
    getCurrentDate() {
      const date = new Date()
      date.setDate(date.getDate() + 3)
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return `${year}-${month}-${day}`
    },
    getLoanDetail() {
      this.$http.get(`/v1/loan/${this.$route.params.id}`)
        .then(response => {
          const resp = response.data.data
          this.form.loan_start = resp.loan_start_raw
          this.form.loan_end = resp.loan_end_raw
          this.form.with_exception = resp.with_exception
          this.form.loan_reason = resp.loan_reason ? resp.loan_reason : ''
          this.form.description = resp.description ? resp.description : ''
          this.form.location_permission_path = resp.location_permission_path
          this.resetStatus = true
          this.form.inventory_type = resp.inventories[0].type
          this.getInventoryList()
          this.userSelected = resp.members.map(member => ({
            value: member.id,
            title: member.name,
          }))

          this.requester = {
            value: resp.requester.id,
            title: resp.requester.name,
          }

          resp.inventories.forEach(inventory => {
            this.items.push({
              id: this.nextTodoId += this.nextTodoId,
              inventory_id: {
                value: inventory.id,
                title: `${inventory.inventory_code} - ${inventory.name}`,
              },
            })

            this.$nextTick(() => {
              this.trAddHeight(this.$refs.row[0].offsetHeight)
            })
          })
        })
        .catch(error => {
          this.errorToast(error.response.data.messages)
        })
    },
    handleSave() {
      this.form.members = []
      this.form.inventories = []
      if (this.requester != null) {
        this.form.requester_id = this.requester.value
      }

      if (this.userSelected.length > 0) {
        this.userSelected.forEach(user => {
          this.form.members.push({
            id: user.value,
          })
        })
      }

      this.items.forEach(item => {
        this.form.inventories.push({
          id: item.inventory_id.value,
        })
      })

      const formData = new FormData()
      formData.append('loan_start', this.form.loan_start)
      formData.append('loan_end', this.form.loan_end)
      formData.append('with_exception', this.form.with_exception ? 1 : 0)
      formData.append('loan_reason', this.form.loan_reason ? this.form.loan_reason : '')
      formData.append('description', this.form.description ? this.form.description : '')
      formData.append('requester_id', this.form.requester_id)
      formData.append('members', JSON.stringify(this.form.members))
      formData.append('inventories', JSON.stringify(this.form.inventories))

      if (this.form.file_location_permission) {
        formData.append('file_location_permission', this.form.file_location_permission)
      }

      if (this.$route.params.id) {
        this.$http.post(`/v1/loan/${this.$route.params.id}?_METHOD=PUT`, formData)
          .then(() => {
            this.successToast()
            this.$router.push('/loan')
          })
          .catch(error => {
            this.errors = error.response.data.errors
            this.errorToast(error.response.data.messages)
          })
      } else {
        this.$http.post('/v1/loan', formData)
          .then(() => {
            this.successToast()
            this.$router.push('/loan')
          })
          .catch(error => {
            this.errors = error.response.data.errors
            this.errorToast(error.response.data.messages)
          })
      }
    },
    handleReset() {
      this.items = []
      this.inventoryList = []
      this.form = {
        loan_start: this.getCurrentDate(),
        loan_end: this.getCurrentDate(),
        with_exception: false,
        inventory_type: null,
      }

      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    successToast() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Berhasil',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    errorToast(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
